<template>
  <div>
    <el-cascader size="large" :options="options" v-model="selectedOptions" @change="handleChange"> </el-cascader>
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from 'element-china-area-data';
export default {
  name: 'ChinaArea',
  props: ['code'],
  data() {
    return {
      options: regionData,
      selectedOptions: [],
    };
  },
  created() {
    let _this = this;
    if (this.code && this.code.length === 3 && _this.code[0] && _this.code[1] && _this.code[2]) {
      let code_arr = [];
      code_arr.push(TextToCode[_this.code[0]]);
      code_arr.push(TextToCode[_this.code[0]][_this.code[1]]);
      code_arr.push(TextToCode[_this.code[0]][_this.code[1]][_this.code[2]]);
      for (let i = 0; i < code_arr.length; i++) {
        this.selectedOptions.push(code_arr[i].code);
      }
    }
  },
  watch: {
    code() {
      let _this = this;
      if (this.code && this.code.length === 3 && _this.code[0] && _this.code[1] && _this.code[2]) {
        let code_arr = [];
        code_arr.push(TextToCode[_this.code[0]]);
        code_arr.push(TextToCode[_this.code[0]][_this.code[1]]);
        code_arr.push(TextToCode[_this.code[0]][_this.code[1]][_this.code[2]]);
        for (let i = 0; i < code_arr.length; i++) {
          this.selectedOptions.push(code_arr[i].code);
        }
      }
    },
  },
  methods: {
    handleChange(value) {
      var loc = [];
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc.push(CodeToText[this.selectedOptions[i]]);
      }
      this.$emit('areaChange', loc);
    },
  },
};
</script>

<style scoped></style>
